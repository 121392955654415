import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentDefault({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentDefault">
      <div className="container lg:grid grid-cols-10 py-5">
        {fields.appearance?.contentAlignment === 'right' && <div className="col-span-4" />}
        <div className="col-span-6">
          <Content className="children-p:mb-4 children-h3:text-xl">{fields?.description}</Content>
        </div>
        {fields.appearance?.contentAlignment === 'left' && <div className="col-span-4" />}
      </div>
    </section>
  )
}
