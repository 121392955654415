import { LossePlaatjie } from '@ubo/losse-sjedel'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Gallery } from '~/graphql/types'

export default function GallerySlider({ fields }: { fields: Page_Flexcontent_Flex_Gallery }) {
  return (
    <section id="GallerySlider" className="section relative overflow-hidden">
      <div className="container">
        <Slider
          mousewheel={{
            forceToAxis: true
          }}
          className="relative"
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 15
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 25
            }
          }}
          navigation={{
            prevEl: '.prevbutton',
            nextEl: '.nextbutton'
          }}
          slidesPerView={3}
          spaceBetween={25}
        >
          {fields.gallery?.map((gal, index) => {
            return (
              <Slide className="!h-auto" key={index}>
                <LossePlaatjie maxWidth={1400} className="h-full object-cover" src={gal?.image} />
              </Slide>
            )
          })}
        </Slider>
        <div className="flex justify-center gap-6 pb-6 pt-6 lg:pt-10">
          <div role="button" className="prevbutton group cursor-pointer">
            <svg className="h-[40px] w-[40px] lg:h-[68px] lg:w-[68px]" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.6569 27.2929L18.2929 33.6569C17.9024 34.0474 17.9024 34.6805 18.2929 35.0711L24.6569 41.435C25.0474 41.8256 25.6805 41.8256 26.0711 41.435C26.4616 41.0445 26.4616 40.4113 26.0711 40.0208L21.4142 35.364H49C49.5523 35.364 50 34.9162 50 34.364C50 33.8117 49.5523 33.364 49 33.364H21.4142L26.0711 28.7071C26.4616 28.3166 26.4616 27.6834 26.0711 27.2929C25.6805 26.9024 25.0474 26.9024 24.6569 27.2929Z"
                fill="#231F20"
                className="smooth group-hover:fill-zve-corphish"
              />
              <circle cx="34" cy="34" r="33" className="smooth group-hover:stroke-zve-corphish" stroke="#231F20" stroke-width="2" />
            </svg>
          </div>
          <div role="button" className="nextbutton group cursor-pointer">
            <svg
              className="pointer-events-none h-[40px] w-[40px] lg:h-[68px] lg:w-[68px]"
              viewBox="0 0 68 68"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.3431 27.2929L49.7071 33.6569C50.0976 34.0474 50.0976 34.6805 49.7071 35.0711L43.3431 41.435C42.9526 41.8256 42.3195 41.8256 41.9289 41.435C41.5384 41.0445 41.5384 40.4113 41.9289 40.0208L46.5858 35.364H19C18.4477 35.364 18 34.9162 18 34.364C18 33.8117 18.4477 33.364 19 33.364H46.5858L41.9289 28.7071C41.5384 28.3166 41.5384 27.6834 41.9289 27.2929C42.3195 26.9024 42.9526 26.9024 43.3431 27.2929Z"
                fill="#231F20"
                className="smooth group-hover:fill-zve-corphish"
              />
              <circle
                cx="34"
                cy="34"
                r="33"
                transform="matrix(-1 0 0 1 68 0)"
                className="smooth group-hover:stroke-zve-corphish"
                stroke="#231F20"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  )
}
