import { LosseLink, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { type LoaderData } from '@ubo/losse-sjedel'
import type { Component_Footer, Maybe } from '~/graphql/types'
import Content from '../elements/Content'
import LogoAlternate from '../elements/svg/LogoAlternate'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer } = useLoaderData<FooterLoaderData>()

  return (
    <footer className="bg-zve-black text-white">
      <div className="container">
        <div className="grid grid-cols-10 border-b-2 border-white py-8 sm:py-16 lg:py-28">
          <div className="col-span-10 mb-6 sm:col-span-5 lg:col-span-2 lg:mb-0">
            <LosseLink aria-label="Home" className="mb-4 block" to="/">
              <LogoAlternate />
            </LosseLink>
            <Content className="content-2xl lg:children-h2:text-2xl">{footer.brandingFooter?.description}</Content>
          </div>
          <div className="col-span-10 mb-6 sm:col-span-5 lg:col-span-3 lg:mb-0">
            <Column listType="numbers" title={footer.mostChosenFooter?.title} items={footer.mostChosenFooter?.mostChosen || []} />
            {footer.mostChosenFooter?.socials?.map((edge) => {
              return (
                <LosseLink key={edge?.link?.title} to={edge?.link?.url} className="group mt-9 inline-flex">
                  <LossePlaatjie maxWidth={2000} src={edge?.icon} className="mr-5" />
                  <span className="font-bold text-zve-corphish group-hover:underline">{edge?.link?.title}</span>
                </LosseLink>
              )
            })}
          </div>
          <div className="col-span-10 mb-5 sm:col-span-5 sm:mb-0 lg:col-span-2">
            <Column title={footer.menuFooter?.title} items={footer.menuFooter?.menu || []} />
          </div>
          <div className="col-span-10 sm:col-span-5 lg:col-span-3">
            <Title title={footer.contactFooter?.title} />
            <ul className="flex flex-col">
              {footer.contactFooter?.contactInfo?.map((edge) => {
                return (
                  <li key={edge?.description} className="mb-4 inline-flex items-start">
                    <LossePlaatjie maxWidth={2000} src={edge?.icon} className="mr-5 mt-1" />
                    <Content>{edge?.description}</Content>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <ul className="flex flex-col items-center pt-8 pb-14 sm:flex-row sm:items-start">
          <li className="lg:mr-12">
            &copy; {new Date().getFullYear()} - {footer.bottomFooter?.title}
          </li>
          {footer.bottomFooter?.links?.map((edge) => {
            return (
              <li key={edge?.link?.title} className="lg:mr-12">
                <LosseLink to={edge?.link?.url} className="hover:text-zve-corphish">
                  {edge?.link?.title}
                </LosseLink>
              </li>
            )
          })}

          <li
            onClick={() => {
              // @ts-ignore
              if (typeof Cookiebot !== 'undefined') {
                // @ts-ignore
                Cookiebot.show()
              }
            }}
            className="lg:mr-12"
          >
            <button className="hover:text-zve-corphish">Cookies</button>
          </li>
        </ul>
      </div>
    </footer>
  )
}

function Title({ title }: { title: Maybe<string> | undefined }) {
  return <h2 className="mb-3 text-lg font-bold">{title}</h2>
}

function Column({
  items,
  title,
  listType = 'none'
}: {
  items: Array<any>
  title: Maybe<string> | undefined
  listType?: 'numbers' | 'none'
}) {
  let listClass = 'list-none'
  let listPaddingClass = 'pl-0'
  let itemPaddingClass = 'pl-0'

  if (listType === 'numbers') {
    listClass = 'list-decimal marker:text-zve-corphish'
    listPaddingClass = 'pl-4'
    itemPaddingClass = 'pl-2'
  }

  return (
    <>
      <Title title={title} />
      <ul className={`${listClass} ${listPaddingClass}`}>
        {items?.map((edge) => {
          return (
            <li key={edge?.link?.title} className={`py-1 ${itemPaddingClass}`}>
              <LosseLink to={edge?.link?.url} target={edge?.link?.target} className="py-1 hover:underline">
                <Content>{edge?.link?.title}</Content>
              </LosseLink>
            </li>
          )
        })}
      </ul>
    </>
  )
}
