import { FormDuufField, FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import Preview from '~/components/elements/products/Preview'
import type { Page_Flexcontent_Flex_Products, Product } from '~/graphql/types'

export default function ProductOverview({ fields }: { fields: Page_Flexcontent_Flex_Products }) {
  return (
    <section id="ProductOverview" className="py-5 md:py-10 lg:py-20 xl:py-24">
      <div className="container flex flex-col gap-6 lg:gap-20">
        <div className="mx-auto grid w-full max-w-screen-xl grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-12">
          {fields.products?.edges.map((edge) => {
            const product = edge?.node as Product

            return <Preview fields={product} key={product.databaseId} />
          })}
        </div>
        <div className="mx-auto grid w-full max-w-screen-xl grid-cols-1 lg:grid-cols-2">
          <div className="col-span-1" />
          <div className="col-span-1">
            <Form
              generate={false}
              data={fields.form}
              renderLoading={() => <Loading />}
              renderStatus={({ status, content }) => <Content>{content}</Content>}
            >
              <div className="grid h-full grid-cols-1 gap-x-4 lg:grid-cols-2 lg:gap-x-8">
                <div className="col-span-1 lg:col-span-2">
                  <FormDuufField id={11} />
                </div>
                <div className="col-span-1 lg:col-span-2">
                  <FormDuufField id={1} />
                </div>
                <div className="col-span-1 lg:col-span-2">
                  <FormDuufField id={12} />
                </div>
                <FormDuufGroup hideIds={[1, 11, 12]} />

                <div className="mt-auto">
                  <FormDuufSubmit />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  )
}
