import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import ProductOverview from './ProductOverview'

export default function ProductsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    overview: ProductOverview
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
