import type { ComponentPropsWithoutRef } from 'react'

// Components
import { LosseContent, losseContentToReact } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'

// .button

export default function Content({ ...rest }: ComponentPropsWithoutRef<'div'>) {
  return (
    <LosseContent
      {...rest}
      replace={(domNode: any) => {
        const { name, attribs, children } = domNode

        if (name === 'a') {
          const attributes = { ...attribs }

          attributes.className = attributes.class
          delete attributes.class

          if (attribs.class === 'button') {
            attributes.className = 'btn btn--light !font-bold inline-flex items-center'

            return (
              <Button arrow to={attributes.href} {...attributes}>
                {losseContentToReact(children)}
              </Button>
            )
          }
        }

        return null
      }}
    >
      {rest.children}
    </LosseContent>
  )
}
