import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'

export default function MiscContact({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  return (
    <section id="MiscContact" className="section">
      <div className="container">
        <div className="mx-auto grid max-w-screen-xl grid-cols-1 lg:grid-cols-10">
          <div className="relative col-span-1 overflow-hidden lg:col-span-4 lg:rounded-l-md">
            <LossePlaatjie maxWidth={1000} src={fields.image} alt="contact_image" className="relative" />
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-t from-black via-transparent to-transparent" />
            <div className="absolute bottom-0 left-0 w-full p-4 lg:px-8 lg:py-6">
              <Content className="text-white">{fields.imagedescription}</Content>
            </div>
          </div>
          <div className="col-span-1 flex flex-col justify-between gap-4 bg-zve-galarian-corsola p-4 sm:p-8 lg:col-span-6 lg:gap-9 lg:rounded-r-md xl:px-16 xl:py-12">
            <Content className="border-t-2 border-black">{fields.description}</Content>
            <div className="flex flex-col justify-start gap-4 sm:flex-row">
              {fields.links?.map((link, index) => (
                <LosseLink
                  className={clsx(index === 0 && 'btn btn--primary', index === 1 && 'btn btn--primary-outline', 'uppercase')}
                  key={index}
                  to={link?.link?.url}
                >
                  {link?.link?.title}
                </LosseLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
