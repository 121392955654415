import type { ComponentPropsWithoutRef } from 'react'
import { LosseLink } from '@ubo/losse-sjedel'
import Arrow from './svg/Arrow'

export interface ButtonProps extends ComponentPropsWithoutRef<any> {
  onClick?: () => void
  to?: string
  arrow?: boolean
}

export default function Button({ arrow = false, children, className = '', ...props }: ButtonProps) {
  return (
    <LosseLink className={`${className}`} {...props}>
      {children}
      {arrow && <Arrow className="ml-3" />}
    </LosseLink>
  )
}
