import { LosseFlex } from '@ubo/losse-sjedel'
import type { Page_Flexcontent } from '~/graphql/types'

// Flex components
import BannerShell from './Banner/BannerShell'
import ContentShell from './Content/ContentShell'
import Fragment from './Fragment'
import FormShell from './Form/FormShell'
import PostsShell from './Posts/PostsShell'
import GalleryShell from './Gallery/GalleryShell'
import FeaturedShell from './Featured/FeaturedShell'
import MiscShell from './Misc/MiscShell'
import KlantcasesShell from './Klantcases/KlantcasesShell'
import ProductsShell from './Products/ProductsShell'

interface FlexContentHandlerProps {
  flex: Page_Flexcontent['flex']
  prefix: string
}

export default function FlexContentHandler({ flex, prefix = 'page_Flexcontent' }: FlexContentHandlerProps) {
  const flexers = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Content`]: ContentShell,
    [`${prefix}_Flex_Gallery`]: GalleryShell,
    [`${prefix}_Flex_Posts`]: PostsShell,
    [`${prefix}_Flex_Featured`]: FeaturedShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Misc`]: MiscShell,
    [`${prefix}_Flex_Klantcases`]: KlantcasesShell,
    [`${prefix}_Flex_Products`]: ProductsShell,
    [`${prefix}_Flex_Fragment`]: Fragment
  }

  // @ts-ignore
  return <LosseFlex flex={flex} flexers={flexers} />
}
