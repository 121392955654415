import { useEffect, useState } from 'react'
import { useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import HeaderMenu from './HeaderMenu'

export default function HeaderMobile() {
  const layout = useLosseLayout()
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false)
      layout.setScrollable(true)
    }
    // eslint-disable-next-line
  }, [location.pathname])

  return (
    <div className="block lg:hidden">
      <button
        type="button"
        onClick={() => {
          setIsOpen(!isOpen)

          layout.setScrollable(false)
        }}
        className="z-10 h-12 w-8 text-center"
        aria-label="Open menu"
      >
        <div className="mx-auto my-[5px] h-[2px] w-full bg-white" />
        <div className="mx-auto my-[5px] h-[2px] w-full bg-white" />
        <div className="mx-auto my-[5px] h-[2px] w-full bg-white" />
      </button>
      {isOpen && (
        <div className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-zve-mightyena">
          <div
            className="absolute right-5 top-5 text-center"
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)

              layout.setScrollable(true)
            }}
            aria-label="Close menu"
          >
            <div className="relative ml-4 h-8 w-8 before:absolute before:h-[33px] before:w-[2px] before:rotate-45 before:bg-white after:absolute after:h-[33px] after:w-[2px] after:-rotate-45 after:bg-white" />
          </div>
          <ul className="m-0 w-10/12 pl-0">
            <HeaderMenu />
          </ul>
        </div>
      )}
    </div>
  )
}
