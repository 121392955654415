import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { type Category } from '~/graphql/types'
import Arrow from '../svg/Arrow'

export default function Preview({ category }: { category: Category }) {
  return (
    <LosseLink
      to={`/opleidingen/?_f=${category.databaseId}`}
      key={category.name}
      className="smooth relative flex !h-auto flex-col overflow-hidden rounded-lg bg-zve-furret"
    >
      <div className="relative">
        <LossePlaatjie maxWidth={850} width={850} src={category.recap?.image} className="h-32 object-cover lg:h-48" />
        <div className="absolute top-0 bottom-0 h-full w-full bg-gradient-to-t from-black via-transparent to-transparent" />
        <div className="absolute bottom-0 left-0 w-full px-8 py-4">
          <div className="font-semibold text-white">{`${category.educations?.pageInfo?.total} ${category.educations?.pageInfo?.total === 1 ? `opleiding` : `verschillende opleidingen`
            }`}</div>
        </div>
      </div>
      <div className="flex h-full min-h-[200px] flex-col p-4 font-bold lg:min-h-[250px] lg:p-7">
        <span>{category.name}</span>
        <h2 className="mt-4 mb-4 text-xl lg:text-2xl">{`${category.recap?.fullName} opleidingen`}</h2>
        <LosseLink
          to={`/opleidingen/?_f=${category.databaseId}`}
          className="group mt-auto inline-flex items-center text-lg text-zve-delphox"
        >
          <span>MEER INFO</span>
          <Arrow className="translate-x-3 transition-transform group-hover:translate-x-5" />
        </LosseLink>
      </div>
    </LosseLink>
  )
}
