import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function Centered({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section id="Centered" className="section">
      <div className="container grid grid-cols-3 lg:grid-cols-5">
        <div className="col-span-1" />
        <div className="col-span-3">
          <Content>{fields?.description}</Content>
        </div>
        <div className="col-span-1" />
      </div>
    </section>
  )
}
