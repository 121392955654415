import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImageWithDescription({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section id="ContentImageWithDescription" className="section">
      <div className="container">
        <div className="mx-auto grid max-w-screen-xl grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-16">
          <div className="flex flex-col items-end gap-1">
            <LossePlaatjie maxWidth={2000} src={fields.image} alt="imagewithdescription" />
            <Content className="children-p:text-sm">{fields.seconddescription}</Content>
          </div>
          <div className="flex items-center">
            <Content className="children-strong:mb-3 children-strong:block">{fields.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
