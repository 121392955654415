import Content from '~/components/elements/Content'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Slider, { Slide } from '~/components/elements/Slider'
import Arrow from '~/components/elements/svg/Arrow'
import { useState } from 'react'
import clsx from 'clsx'
import Preview from '~/components/elements/categories/Preview'

export default function BannerCategories({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0)

  return (
    <section data-component="BannerCategories" className="bg-zve-galarian-corsola">
      <div className="relative overflow-hidden">
        <LossePlaatjie maxWidth={2000} loading="eager" className="absolute h-full w-full object-cover" src={fields.image} />
        <div className="absolute inset-0 bg-[#F68D5A] opacity-70 mix-blend-color-burn" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-black/20" />
        <div className="relative mx-auto grid h-full max-w-screen-2xl grid-cols-7 items-center gap-4 pt-32 pb-20 pl-6 pr-4 sm:pt-44 lg:items-end lg:gap-0 lg:pl-12 lg:pr-8 xl:grid-cols-11">
          <div className="col-span-7 mb-7 md:mb-0 lg:col-span-3 xl:col-span-5">
            <Content className="mb-8 text-white children-h1:text-6xl lg:mb-24 xl:children-h1:text-7xl 2xl:children-h1:text-8xl">
              {fields?.description}
            </Content>
            <div className="inline-flex items-center overflow-hidden rounded-full bg-white px-4 py-1 md:py-2 md:px-10">
              <LossePlaatjie className="mr-3" src={fields.certification?.image} />
              <Content className="children-br:hidden children-strong:children-p:mb-0">{fields.certification?.description}</Content>
            </div>
          </div>
          <div className="relative col-span-7 h-full overflow-hidden rounded-md bg-white py-6 px-6 lg:col-span-4 lg:px-10 lg:py-14 xl:col-span-6">
            <div className="pointer-events-none absolute top-0 right-0 z-10 h-full w-1/5 bg-gradient-to-l from-white via-white" />
            <Content className="children-h2:text-3xl children-h2:leading-[1.1] lg:children-h2:text-5xl">{fields.sliderDescription}</Content>
            <div className="mt-7 mb-9 flex text-lg font-bold">
              <button className="custom-btn-prev-categories mr-7 inline-flex items-center text-zve-corphish">
                <Arrow className="rotate-180" />
                <span className="ml-3">VORIGE</span>
              </button>
              <button className="custom-btn-next-categories inline-flex items-center text-zve-corphish">
                <span className="mr-3">VOLGENDE</span>
                <Arrow />
              </button>
            </div>
            <Slider
              breakpoints={{
                0: {
                  slidesPerView: 1.4,
                  spaceBetween: 20
                },
                767: {
                  slidesPerView: 2.4,
                  spaceBetween: 20
                },
                1024: {
                  slidesPerView: 1.6,
                  spaceBetween: 30
                }
              }}
              mousewheel={{
                forceToAxis: true,
                sensitivity: 0.1
              }}
              navigation={{
                prevEl: '.custom-btn-prev-categories',
                nextEl: '.custom-btn-next-categories'
              }}
              onSlideChange={(slide) => setCurrentCategoryIndex(slide.activeIndex)}
            >
              {fields.categories?.map((category, categoryIndex) => {
                const active = currentCategoryIndex === categoryIndex

                if (!category) return null

                return (
                  <Slide key={category.name} className={clsx(active ? `` : `!scale-90`, 'smooth')}>
                    <Preview category={category} />
                  </Slide>
                )
              })}
              <Slide />
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}
