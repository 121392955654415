import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContactInfo({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section id="ContactInfo" className="section bg-zve-mightyena">
      <div className="container">
        <div className="mx-auto grid max-w-screen-lg grid-cols-2 lg:grid-cols-5">
          <div className="col-span-2" />
          <div className="col-span-2 grid grid-cols-1 gap-y-6 lg:col-span-3 lg:grid-cols-2 lg:gap-y-20">
            <Content className="text-white children-h2:text-4xl">{fields.description}</Content>
            <Content className="text-white children-h2:text-4xl">{fields.seconddescription}</Content>
            <div className="col-span-1 flex flex-col gap-5 lg:col-span-2">
              <Content className="text-white children-h2:text-4xl">{fields.facebookdescription}</Content>
              <div className="flex">
                <Button target={fields.link?.target} className="btn btn--facebook" to={fields.link?.url || '/'}>
                  <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.9515 16.875L15.7812 11.4457H10.5935V7.92246C10.5935 6.43711 11.3182 4.98926 13.6416 4.98926H16V0.366797C16 0.366797 13.8598 0 11.8136 0C7.54139 0 4.74889 2.60039 4.74889 7.30781V11.4457H0V16.875H4.74889V30H10.5935V16.875H14.9515Z"
                      fill="white"
                    />
                  </svg>
                  {fields.link?.title}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
