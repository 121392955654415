/* eslint-disable react-hooks/exhaustive-deps */
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { useEffect, useRef } from 'react'
import Content from '~/components/elements/Content'
import Arrow from '~/components/elements/svg/Arrow'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function AboutWithScroll({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const ref = useRef(null)

  const handleScroll = () => {
    const parent = document.querySelector('.parent')
    const inner = document.querySelector('.inner')

    if (ref.current) {
      // @ts-ignore
      if (ref.current.getBoundingClientRect().top > 0) {
        // @ts-ignore
        inner?.setAttribute('style', `top: 0px;`)
      } else if (
        // @ts-ignore
        ref.current.getBoundingClientRect().top <= 0 &&
        // @ts-ignore
        ref.current.getBoundingClientRect().top + ((parent?.clientHeight || 0) - 100 - (inner?.clientHeight || 0)) >= 0
      ) {
        // @ts-ignore
        inner?.setAttribute('style', `top: ${Math.abs(ref.current.getBoundingClientRect().top) + 0}px`)
      } else {
        // @ts-ignore
        inner?.setAttribute('style', `top: ${parent?.clientHeight - inner?.clientHeight - 100}px;`)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <section ref={ref} id="AboutWithScroll" className="parent">
      <div className="bg-zve-galarian-corsola py-6 md:py-8 lg:py-10">
        <div className="container grid grid-cols-10 gap-6 lg:gap-24 lg:py-5">
          <div className="relative col-span-10 sm:col-span-4">
            <Content className="inner content-7xl content-border lg:absolute">{fields.title}</Content>
          </div>
          <div className="col-span-10 flex flex-col gap-7 sm:col-span-6">
            <LossePlaatjie maxWidth={2000} className="w-full max-w-[unset] object-cover" src={fields.image} />
            {fields.seconddescription && (
              <>
                <Content>{fields?.seconddescription}</Content>
                {fields.link && (
                  <LosseLink className="btn btn--primary" to={fields.link?.url}>
                    <span>{fields.link?.title}</span>
                    <Arrow />
                  </LosseLink>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white py-6 md:py-8 lg:py-10">
        <div className="container grid grid-cols-10 lg:gap-24">
          <div className="col-span-10 lg:col-span-4" />
          <div className="col-span-10 lg:col-span-6">
            <Content className="children-iframe:!aspect-video children-iframe:h-auto">{fields?.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
