import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import Arrow from '~/components/elements/svg/Arrow'
import type { Case, Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentCase({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const { page } = useLoaderData<{ page: Case }>()

  return (
    <section id="ContentCase" className="section !pt-6 lg:!pt-10">
      <div className="container">
        <div className="mx-auto flex max-w-screen-2xl flex-col gap-6 lg:gap-12">
          <div className="flex flex-col justify-between gap-6 rounded-lg bg-zve-galarian-corsola p-3 lg:flex-row lg:p-6">
            <div className="flex flex-row gap-3 lg:gap-6">
              <div className="flex h-[112px] w-[112px] items-center justify-center rounded-[6px] bg-white p-5">
                <LossePlaatjie maxWidth={400} src={page.recap?.logo} />
              </div>
              <div className="flex flex-col justify-between text-sm lg:text-base">
                <div className="grid grid-cols-3">
                  <div className="col-span-1 font-bold">Wat:</div>
                  <div className="col-span-2">{page.recap?.what}</div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="col-span-1 font-bold">Klant:</div>
                  <div className="col-span-2">{page.recap?.customer}</div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="col-span-1 font-bold">Wanneer:</div>
                  <div className="col-span-2">{page.recap?.when}</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-2">
              <Content className="children-h3:text-lg children-h3:leading-[22px]">{fields.seconddescription}</Content>
              <Button className="btn btn--primary-bare" to={fields.link?.url || '/'}>
                {fields.link?.title}
                <Arrow />
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-12">
            <LossePlaatjie src={fields.image} alt="case-image" />
            <div className="flex items-center">
              <Content className="content-9xl children-h2:text-6xl lg:children-h2:text-7xl xl:children-h2:text-8xl 2xl:children-h2:text-9xl">
                {fields.description}
              </Content>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
