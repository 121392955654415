import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Arrow from '~/components/elements/svg/Arrow'
import type { Page_Flexcontent_Flex_Featured } from '~/graphql/types'

export default function FeaturedEducations({ fields }: { fields: Page_Flexcontent_Flex_Featured }) {
  return (
    <section data-component="FeaturedEducations" className="relative py-12 lg:py-24">
      <div className="absolute top-0 h-1/2 w-full bg-zve-galarian-corsola" />
      <div className="absolute bottom-0 h-1/2 w-full bg-white" />
      <div className="container relative py-5">
        <Content className="content-7xl mb-8">{fields?.description}</Content>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
          {fields.educations?.map((edge) => {
            return (
              <div className="col-span-1 overflow-hidden rounded-md bg-zve-furret" key={edge?.title}>
                <div className="relative">
                  <LossePlaatjie maxWidth={1280} width={840} src={edge?.recap?.thumbnail} className="h-32 object-cover sm:h-48" />
                  {/* <div className="absolute inset-0 flex w-full items-end text-white">
                    <div className="relative w-full px-7 py-3">
                      <div className="absolute inset-0 bg-gradient-to-t from-zve-black" />
                      <span className="relative font-bold">{edge?.recap?.identification}</span>
                    </div>
                  </div> */}
                </div>
                <div className="p-4 lg:p-8">
                  <span className="mb-4 block font-semibold">{edge?.categories?.edges?.map((edge) => edge?.node?.name)}</span>
                  <h3 className="text-xl font-bold lg:text-2xl">{edge?.title}</h3>
                  <LosseLink to={edge?.uri} className="group mt-5 inline-flex items-center text-lg text-zve-delphox sm:mt-20">
                    <span className="font-bold">{fields.readmoretext}</span>
                    <Arrow className="translate-x-3 transition-transform group-hover:translate-x-5" />
                  </LosseLink>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
