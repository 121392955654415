import { LossePlaatjie } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import ArrowExternal from '~/components/elements/svg/ArrowExternal'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'

export default function MiscSignup({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  return (
    <section id="MiscSignup" className="alt-section bg-zve-mightyena">
      <div className="container">
        <div className="mx-auto grid max-w-6xl grid-cols-1 lg:grid-cols-5 lg:min-h-[310px]">
          <div className="col-span-1 lg:col-span-2">
            <LossePlaatjie
              maxWidth={1000}
              className="max-h-[310px] h-full rounded-l-[4px] object-cover lg:max-h-[unset]"
              src={fields.image}
              alt="signup-image"
            />
          </div>
          <div className="col-span-1 flex flex-col justify-center gap-4 rounded-r-[4px] bg-white p-6 lg:col-span-3 lg:px-14 lg:py-2">
            <Content className="border-t-2 border-zve-mightyena children-h3:text-2xl children-h3:font-semibold children-h3:text-zve-corphish">
              {fields.description}
            </Content>
            <div className="flex flex-row gap-3">
              {fields.links?.map((link, index) => (
                <Button className="btn btn--primary" key={index} src={link?.link}>
                  <ArrowExternal />
                  {link?.link?.title}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
