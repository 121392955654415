import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Arrow from '~/components/elements/svg/Arrow'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentImage" className="section bg-zve-galarian-corsola">
      <div className="container grid grid-cols-10 gap-6 lg:gap-0 lg:py-5">
        <div className="col-span-10 sm:col-span-4 lg:pr-24">
          <Content className="content-7xl content-border lg:pr-20">{fields.title}</Content>
          {!fields.description && fields.link && (
            <LosseLink className="btn btn--primary mt-8" to={fields.link?.url}>
              <span>{fields.link?.title}</span>
              <Arrow />
            </LosseLink>
          )}
        </div>
        <div className="col-span-10 sm:col-span-6">
          <LossePlaatjie className="rounded-[6px]" maxWidth={1280} width={1280} src={fields.image} />
          {fields.description && (
            <>
              <Content className="my-7">{fields?.description}</Content>
              {fields.link && (
                <LosseLink className="btn btn--primary" to={fields.link?.url}>
                  <span>{fields.link?.title}</span>
                  <Arrow />
                </LosseLink>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  )
}
