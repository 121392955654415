import { type LoaderData, useLoaderData, pageLoader as loader } from '@ubo/losse-sjedel'

import FlexContentHandler from '~/components/flex/FlexContentHandler'
import Layout from '~/components/layout/Layout'

export { loader }

export default function $page() {
  const {
    page: { contentType, flexContent }
  } = useLoaderData<LoaderData>()

  return (
    <Layout>
      <FlexContentHandler prefix={`${contentType?.node?.name}_Flexcontent`} flex={flexContent?.flex} />
    </Layout>
  )
}
