import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'

export default function Map({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  return (
    <section id="Map" className="bg-zve-mightyena">
      <div className="h-[300px] w-full lg:h-[586px] lg:max-w-[2000px]">
        <iframe
          className="mx-auto"
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2448.457092157808!2d5.387932115793493!3d52.144196579744836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c644236b0a256f%3A0x286a1b3738a20e4c!2sEverard%20Meysterweg%2012a%2C%203817%20HD%20Amersfoort!5e0!3m2!1snl!2snl!4v1675692023801!5m2!1snl!2snl"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </section>
  )
}
