import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentCursus({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section id="ContentCursus" className="section">
      <div className="container">
        <div className="mx-auto max-w-screen-lg">
          <Content className="children-h2:text-3xl children-h2:leading-[1.2] children-h3:text-2xl children-strong:!inline-block lg:children-h2:text-5xl">
            {fields.description}
          </Content>
        </div>
      </div>
    </section>
  )
}
