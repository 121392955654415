import { LossePlaatjie } from '@ubo/losse-sjedel'
import type { Product } from '~/graphql/types'
import Content from '../Content'

export default function Preview({ fields }: { fields: Product }) {
  return (
    <section id="Preview" className="flex flex-col gap-4 lg:gap-7">
      <LossePlaatjie
        maxWidth={1000}
        className="h-[180px] w-full max-w-[unset] rounded-[6px] object-cover"
        src={fields.recap?.image}
        alt="product-image"
      />
      <div className="flex flex-col gap-2 lg:gap-4">
        <h3 className="text-lg font-bold">{fields.title}</h3>
        <Content>{fields.recap?.description}</Content>
      </div>
    </section>
  )
}
