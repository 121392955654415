import type { PropsWithChildren } from 'react'
import { LosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header'

export default function Layout({ children }: PropsWithChildren<{}>) {
  return (
    <LosseLayout>
      <Header />
      <main className="min-h-screen">{children}</main>
      <Footer />
    </LosseLayout>
  )
}
