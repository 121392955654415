import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import PostsCasesSlider from './PostsCasesSlider'
import PostsEducationsNext from './PostsEducationsNext'
import PostsEducations from './PostsEducations'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    cases_slider: PostsCasesSlider,
    educations_next: PostsEducationsNext,
    educations: PostsEducations
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
