import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import KlantcasesPreview from './KlantcasesPreview'
import OtherCases from './OtherCases'

export default function KlantcasesShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    preview: KlantcasesPreview,
    other: OtherCases
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
