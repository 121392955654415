import { useState } from 'react'
import { LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'
import clsx from 'clsx'

export default function Menu() {
  const { header, rest } = useLoaderData<HeaderLoaderData>()
  const [open, setOpen] = useState(-1)
  const [currentIndex, setCurrentIndex] = useState(-1)

  return (
    <>
      {header?.menuHeader?.map((edge, index) => {
        const active = currentIndex === index

        return (
          <motion.li onHoverStart={() => setOpen(index)} onHoverEnd={() => setOpen(-1)} className="relative px-2 xl:px-3" key={index}>
            <div className="flex flex-wrap items-center">
              <LosseLink
                onMouseEnter={() => setCurrentIndex(index)}
                onMouseLeave={() => setCurrentIndex(-1)}
                className={clsx(
                  edge?.outline ? 'lg:btn--rounded--sm lg:btn--outline px-1' : 'px-1',
                  'group relative overflow-hidden py-3 text-3xl font-semibold uppercase tracking-[0.035em] text-white aria-current-page:rounded-full aria-current-page:bg-zve-corphish aria-current-page:px-4 lg:py-2 lg:text-base xl:text-lg'
                )}
                to={edge?.link?.url || '/'}
                target={edge?.link?.target || ''}
              >
                <div className="flex flex-row items-center gap-2">
                  <span className="block lg:text-[14px] xl:text-base">{edge?.link?.title}</span>
                  {edge?.link?.title === 'Opleidingen' && (
                    <span className="flex h-6 w-6 items-center justify-center rounded-full bg-zve-corphish text-[14px] font-semibold text-zve-galarian-corsola group-aria-current-page:bg-zve-mightyena">
                      {rest.educations.pageInfo.total}
                    </span>
                  )}
                </div>

                {edge?.link?.title !== 'Contact' && (
                  <AnimatePresence mode="wait">
                    {active && (
                      <motion.div
                        className="absolute bottom-0 h-[0.125rem] w-full bg-zve-furret"
                        initial={{ x: '-110%' }}
                        animate={{ x: 0 }}
                        exit={{ x: '110%' }}
                      />
                    )}
                  </AnimatePresence>
                )}
              </LosseLink>
            </div>
            {edge?.submenu && open === index && (
              <AnimatePresence>
                <HeaderSubMenu fields={edge.submenu} />
              </AnimatePresence>
            )}
          </motion.li>
        )
      })}
    </>
  )
}
