import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import FeaturedEducations from './FeaturedEducations'

export default function FeaturedShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    educations: FeaturedEducations
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
