import type { Education, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import Content from '~/components/elements/Content'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import ArrowExternal from '~/components/elements/svg/ArrowExternal'

export default function PostsEducationsNext({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="PostsEducationsNext" className="bg-zve-galarian-corsola py-8 sm:py-12 lg:py-20">
      <div className="container flex flex-col justify-between md:flex-row">
        <div className="mb-7 w-full md:mb-0 md:w-[40%]">
          <Content className="content-7xl content-border mb-6 children-headings:mb-6">{fields.description}</Content>
          <LosseLink className="group inline-flex gap-x-3 items-center text-lg font-bold text-zve-corphish" to={fields.link?.url}>
            <ArrowExternal />
            <span className="group-hover:underline">{fields.link?.title}</span>
          </LosseLink>
          <Content className="content-7xl mb-6 mt-6 children-headings:mb-6 lg:mt-10">{fields.seconddescription}</Content>
          <LosseLink className="group inline-flex gap-x-3 items-center text-lg font-bold text-zve-corphish" to="/contact/">
            <ArrowExternal />
            <span className="group-hover:underline uppercase">Neem contact op</span>
          </LosseLink>
        </div>
        <div className="w-full md:w-[50%]">
          <LossePlaatjie maxWidth={1280} width={1280} src={fields.image} className="mb-7" />
          <h3 className="mb-4 text-3xl font-bold text-zve-corphish">Uitgelichte cursussen</h3>
          <div className="flex flex-col gap-8">
            {fields.posts?.edges
              ?.sort((a, b) => {
                const _a = a?.node as Education
                const _b = b?.node as Education

                const _aDate = new Date(_a.recap?.nextDate || '')
                const _bDate = new Date(_b.recap?.nextDate || '')

                return Number(`0.${_aDate.getTime()}`) - Number(`0.${_bDate.getTime()}`)
              })
              .slice(0, fields.limit || 3)
              .map((edge, i) => {
                const education = edge?.node as Education

                if (!education.recap?.nextDate) return null

                return (
                  <div key={education.title} className="grid grid-cols-2 border-t-2 border-zve-black lg:grid-cols-4">
                    <div className="col-span-1">
                      <LosseLink to={education?.uri} className="text-lg lg:font-bold underline">
                        Boek nu
                      </LosseLink>
                    </div>
                    <div className="col-span-1 justify-self-end lg:justify-self-auto">
                      <span className="text-lg lg:font-bold">{education.recap.location}</span>
                    </div>
                    <div className="col-span-2">
                      <h4 className="text-lg font-bold">{education.title}</h4>
                      <Content className="py-4">{education.recap.excerpt}</Content>
                      {i === Number(fields.limit) - 1 && (
                        <LosseLink
                          className="group inline-flex gap-x-3 items-center text-lg font-bold text-zve-corphish"
                          to={fields.link?.url}
                        >
                          <ArrowExternal />
                          <span className="group-hover:underline">{fields.readmoretext}</span>
                        </LosseLink>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </section>
  )
}
