import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function Quote({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section id="Quote" className="sep-section bg-zve-black">
      <div className="container">
        <div className="mx-auto flex max-w-screen-xl flex-col gap-6 lg:flex-row lg:gap-28">
          <div className="flex flex-col items-center gap-8">
            <LossePlaatjie className="h-[180px] w-[180px] min-w-[180px]" maxWidth={1000} src={fields.image} alt="quote_person" />
            <Content className="text-zve-corphish children-br:hidden children-strong:!mb-0 children-strong:text-white">
              {fields.seconddescription}
            </Content>
          </div>
          <div className="flex flex-col items-start">
            <div className="h-[2px] w-[500px] bg-white" />
            <Content className="content-6xl sm:content-7xl text-white children-h2:text-2xl lg:children-h2:text-5xl">
              {fields.description}
            </Content>
          </div>
        </div>
      </div>
    </section>
  )
}
