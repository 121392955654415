import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import GalleryDefault from './GalleryDefault'
import GallerySmall from './GallerySmall'
import GallerySlider from './GallerySlider'

export default function GalleryShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: GalleryDefault,
    small: GallerySmall,
    slider: GallerySlider
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
