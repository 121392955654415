import { useLosseBlogBink } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function Pagination() {
  const { hasPrevPage, hasNextPage, prevPage, nextPage } = useLosseBlogBink()

  return (
    <div className="bg-ab-mist mt-10 flex h-[40px] justify-end gap-3 rounded-full">
      <button
        type="button"
        className={clsx(
          'bg-ab-mist button-base-ring border-ab-tidal flex h-8 w-8 items-center justify-center rounded-full text-white disabled:opacity-25 lg:h-10 lg:w-10',
          hasPrevPage && 'children-path:hover:!fill-ab-tidal hover:text-ab-tidal focus:ring-ab-tidal focus:ring-offset-white'
        )}
        disabled={!hasPrevPage}
        onClick={prevPage}
      >
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            className="transition-all duration-200"
            d="M5.52741 9.47329C5.5899 9.41132 5.63949 9.33758 5.67334 9.25634C5.70718 9.1751 5.72461 9.08797 5.72461 8.99996C5.72461 8.91195 5.70718 8.82481 5.67334 8.74357C5.63949 8.66233 5.5899 8.5886 5.52741 8.52663L2.47408 5.47329C2.4116 5.41132 2.362 5.33758 2.32816 5.25634C2.29431 5.1751 2.27689 5.08797 2.27689 4.99996C2.27689 4.91195 2.29431 4.82481 2.32816 4.74357C2.362 4.66233 2.4116 4.5886 2.47408 4.52663L5.52741 1.47329C5.5899 1.41132 5.63949 1.33758 5.67334 1.25634C5.70718 1.1751 5.72461 1.08797 5.72461 0.999959C5.72461 0.911951 5.70718 0.824814 5.67334 0.743574C5.63949 0.662335 5.5899 0.588601 5.52741 0.526625C5.4025 0.402458 5.23354 0.332764 5.05741 0.332764C4.88129 0.332764 4.71232 0.402458 4.58741 0.526625L1.52742 3.58663C1.15289 3.96163 0.942513 4.46996 0.942513 4.99996C0.942513 5.52996 1.15289 6.03829 1.52742 6.41329L4.58741 9.47329C4.71232 9.59746 4.88129 9.66715 5.05741 9.66715C5.23353 9.66715 5.4025 9.59746 5.52741 9.47329Z"
            fill="white"
          />
        </svg>
      </button>
      <button
        type="button"
        className={clsx(
          'bg-ab-mist button-base-ring border-ab-tidal flex h-8 w-8 items-center justify-center rounded-full text-white disabled:opacity-25 lg:h-10 lg:w-10',
          hasPrevPage && 'children-path:hover:!fill-ab-tidal hover:text-ab-tidal focus:ring-ab-tidal focus:ring-offset-white'
        )}
        disabled={!hasNextPage}
        onClick={nextPage}
      >
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            className="transition-all duration-200"
            d="M0.472589 0.526709C0.410103 0.588684 0.360507 0.662417 0.326661 0.743656C0.292816 0.824896 0.27539 0.912033 0.27539 1.00004C0.27539 1.08805 0.292816 1.17519 0.326661 1.25643C0.360507 1.33767 0.410103 1.4114 0.472589 1.47337L3.52592 4.52671C3.5884 4.58868 3.638 4.66242 3.67184 4.74366C3.70569 4.8249 3.72311 4.91203 3.72311 5.00004C3.72311 5.08805 3.70569 5.17519 3.67184 5.25643C3.638 5.33766 3.5884 5.4114 3.52592 5.47337L0.472589 8.52671C0.410104 8.58868 0.360507 8.66242 0.326662 8.74366C0.292816 8.8249 0.275391 8.91203 0.275391 9.00004C0.275391 9.08805 0.292816 9.17519 0.326662 9.25643C0.360507 9.33767 0.410104 9.4114 0.472589 9.47337C0.597497 9.59754 0.766465 9.66724 0.942588 9.66724C1.11871 9.66724 1.28768 9.59754 1.41259 9.47337L4.47258 6.41337C4.84711 6.03837 5.05749 5.53004 5.05749 5.00004C5.05749 4.47004 4.84711 3.96171 4.47258 3.58671L1.41259 0.526709C1.28768 0.402541 1.11871 0.332846 0.942588 0.332846C0.766464 0.332846 0.597497 0.402541 0.472589 0.526709Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
  )
}
