import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import MiscContact from './MiscContact'
import MiscSignup from './MiscSignup'
import Map from './Map'

export default function GalleryShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    contact: MiscContact,
    signup: MiscSignup,
    map: Map
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
