import { LossePlaatjie } from '@ubo/losse-sjedel'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Arrow from '~/components/elements/svg/Arrow'
import type { Page_Flexcontent_Flex_Gallery } from '~/graphql/types'

export default function GallerySmall({ fields }: { fields: Page_Flexcontent_Flex_Gallery }) {
  const [interactedVideo, setInteractedVideo] = useState(false)

  return (
    <section data-component="GalleryDefault" className="section">
      <div className="container">
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-5 gap-4 lg:gap-5">
            {fields.gallery?.map((edge, galleryIndex) => {
              let colClass = 'col-span-3 sm:col-span-2'

              if (galleryIndex % 2 === 0) {
                colClass = 'col-span-2'
              } else {
                colClass = 'col-span-3'
              }

              return (
                <div className={`${colClass} relative overflow-hidden rounded-lg`} key={edge?.image?.altText}>
                  {edge?.videoUrl ? (
                    <div className="flex items-center justify-center">
                      <video autoPlay muted={!interactedVideo} controls={interactedVideo}>
                        <source src={edge?.videoUrl || ''} type="video/mp4" />
                      </video>
                      {!interactedVideo && <div className="absolute inset-0 bg-zve-black opacity-70 mix-blend-multiply" />}
                      <AnimatePresence>
                        {!interactedVideo && (
                          <motion.button
                            initial={{ x: 0, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            exit={{ x: ['-20%', '100%'], opacity: 0 }}
                            transition={{
                              duration: 1
                            }}
                            className="btn--rounded btn--light absolute"
                            onClick={() => setInteractedVideo(true)}
                          >
                            <span>BEKIJK VIDEO</span>
                            <Arrow className="ml-3" />
                          </motion.button>
                        )}
                      </AnimatePresence>
                    </div>
                  ) : (
                    <LossePlaatjie maxWidth={1000} src={edge?.image} className="h-full w-full object-cover" />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
