export default function Arrow({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" fill="none" viewBox="0 0 32 16" className={className}>
      <path
        fill="currentColor"
        d="M1 7a1 1 0 000 2V7zm30.707 1.707a1 1 0 000-1.414L25.343.929a1 1 0 10-1.414 1.414L29.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM1 9h30V7H1v2z"
      />
    </svg>
  )
}
