import Content from '~/components/elements/Content'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDefault" className="relative">
      <div className="absolute inset-0">
        <LossePlaatjie maxWidth={2000} loading="eager" className="h-full w-full overflow-hidden object-cover" src={fields.image} />
        <div className="absolute inset-0 bg-zve-black opacity-50" />
      </div>
      <div className="container relative flex flex-col items-start justify-between gap-6 pt-48 pb-6 lg:flex-row lg:items-end lg:gap-0 lg:pt-60 lg:pb-10 xl:pb-20 xl:pt-80">
        <Content className="content-9xl text-white children-p:text-2xl">{fields?.description}</Content>
        <div className="flex items-center overflow-hidden rounded-full bg-white px-7 py-2">
          <LossePlaatjie maxWidth={500} src={fields.certification?.image} className="mr-3 h-10 w-9" />
          <Content>{fields.certification?.description}</Content>
        </div>
      </div>
    </section>
  )
}
