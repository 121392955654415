import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import ContentDefault from './ContentDefault'
import ContentImage from './ContentImage'
import ContentAlternativeImage from './ContentAlternativeImage'
import ContentTarget from './ContentTarget'
import ContentCursus from './ContentCursus'
import ContactInfo from './ContactInfo'
import AboutWithScroll from './AboutWithScroll'
import ContentCase from './ContentCase'
import Quote from './Quote'
import Centered from './Centered'
import ContentImageWithDescription from './ContentImageWithDescription'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: ContentDefault,
    image: ContentImage,
    alternativeimage: ContentAlternativeImage,
    target: ContentTarget,
    cursus: ContentCursus,
    contact: ContactInfo,
    aboutwithscroll: AboutWithScroll,
    case: ContentCase,
    quote: Quote,
    centered: Centered,
    imagewithdescription: ContentImageWithDescription
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
