import { LossePlaatjie } from '@ubo/losse-sjedel'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Content from '~/components/elements/Content'
import Arrow from '~/components/elements/svg/Arrow'
import type { Page_Flexcontent_Flex_Gallery } from '~/graphql/types'

export default function GalleryDefault({ fields }: { fields: Page_Flexcontent_Flex_Gallery }) {
  const [interactedVideo, setInteractedVideo] = useState(false)

  return (
    <section data-component="GalleryDefault" className="py-12 lg:py-24">
      <div className="container">
        <Content className="content-7xl content-border mb-8 md:w-2/3 lg:w-1/2">{fields?.description}</Content>
      </div>
      <div className="mx-auto grid max-w-9xl grid-cols-6 gap-4 pl-4 pr-4 md:pl-16 md:pr-8 lg:gap-8">
        {fields.gallery?.map((edge, galleryIndex) => {
          let colClass = 'col-span-3 sm:col-span-2'

          if (galleryIndex !== 0) {
            if (galleryIndex % 3 === 0 || galleryIndex % 4 === 0) {
              if (edge?.videoUrl) {
                colClass = 'col-span-6 sm:col-span-3 md:-ml-8 md:mr-8'
              } else {
                colClass = 'col-span-3 sm:-ml-3 sm:mr-3 md:-ml-8 md:mr-8'
              }
            }
          }

          return (
            <div className={`${colClass} relative overflow-hidden rounded-lg`} key={edge?.image?.altText}>
              {edge?.videoUrl ? (
                <div className="flex items-center justify-center">
                  <video autoPlay muted={!interactedVideo} controls={interactedVideo}>
                    <source src={edge?.videoUrl || ''} type="video/mp4" />
                  </video>
                  {!interactedVideo && <div className="absolute inset-0 bg-zve-black opacity-70 mix-blend-multiply" />}
                  <AnimatePresence>
                    {!interactedVideo && (
                      <motion.button
                        initial={{ x: 0, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: ['-20%', '100%'], opacity: 0 }}
                        transition={{
                          duration: 1
                        }}
                        className="btn--rounded btn--light absolute"
                        onClick={() => setInteractedVideo(true)}
                      >
                        <span>BEKIJK VIDEO</span>
                        <Arrow className="ml-3" />
                      </motion.button>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
                <LossePlaatjie maxWidth={1280} width={1280} src={edge?.image} className="h-full w-full object-cover" />
              )}
            </div>
          )
        })}
      </div>
    </section>
  )
}
