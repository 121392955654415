import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import Arrow from '~/components/elements/svg/Arrow'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentAlternativeImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentAlternativeImage" className="bg-zve-galarian-corsola py-4 lg:py-10">
      <div className="container">
        <div className="mx-auto flex max-w-7xl flex-col gap-6">
          <Breadcrumbs />
          <div className="grid grid-cols-10 gap-6 lg:gap-0 lg:py-10">
            <div className="col-span-10 sm:col-span-4 lg:pr-24">
              <Content className="content-7xl content-border pr-20">{fields.title}</Content>
              <Content className="my-7">{fields?.description}</Content>
              {fields.link && (
                <LosseLink className="btn btn--primary uppercase" src={fields.link}>
                  <span>{fields.link?.title}</span>
                  <Arrow className="ml-3" />
                </LosseLink>
              )}
            </div>
            <div className="col-span-10 sm:col-span-6">
              <LossePlaatjie maxWidth={2000} src={fields.image} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
