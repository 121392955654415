import type { Case, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import Slider, { Slide } from '~/components/elements/Slider'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { Navigation } from 'swiper/modules'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Content from '~/components/elements/Content'
import Arrow from '~/components/elements/svg/Arrow'

export default function PostsCasesSlider({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const currentCase = fields.posts?.edges![currentSlideIndex]?.node as Case

  return (
    <section data-component="PostsCasesSlider" className="bg-zve-snorlax pt-12 pb-12 lg:pt-12">
      <div className="container grid grid-cols-2 lg:py-20">
        <div className="relative col-span-2 bg-white p-10 shadow-2xl lg:col-span-1 lg:p-24">
          <div className="mb-8 flex justify-center">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentCase.title}
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 100 }}
                transition={{ duration: 0.3 }}
              >
                <LossePlaatjie maxWidth={2000} src={currentCase.recap?.logo} className="h-32 w-auto" />
              </motion.div>
            </AnimatePresence>
          </div>
          <div className="relative">
            <Slider
              centerInsufficientSlides
              modules={[Navigation]}
              autoplay={false}
              breakpoints={{
                0: {
                  slidesPerView: 5,
                  spaceBetween: 30
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 30
                }
              }}
              navigation={{
                prevEl: '.custom-btn-prev',
                nextEl: '.custom-btn-next'
              }}
            >
              {fields?.posts?.edges?.map((item, i) => {
                const post = item?.node as Case
                return (
                  <Slide key={post?.databaseId} role="button" className="flex !h-auto items-center justify-center">
                    <button
                      type="button"
                      disabled={i === currentSlideIndex}
                      onClick={() => setCurrentSlideIndex(i)}
                      className="disabled:opacity-20"
                    >
                      <LossePlaatjie maxWidth={400} src={post.recap?.logo} className="h-auto w-12 object-cover" />
                    </button>
                  </Slide>
                )
              })}
            </Slider>
            <button className="custom-btn-prev absolute top-6 -left-10 z-10 text-zve-black">
              <Arrow />
            </button>
            <button className="custom-btn-next absolute top-6 -right-10 z-10 text-zve-black">
              <Arrow />
            </button>
          </div>
        </div>
        <AnimatePresence mode="wait">
          <motion.div
            className="relative col-span-2 self-center overflow-hidden py-8 lg:col-span-1 lg:px-10 lg:py-20"
            key={currentCase.title}
            initial={{ opacity: 0, x: '-50%', skewX: -50 }}
            animate={{ opacity: 1, x: 0, skewX: [-50, 0] }}
            exit={{ opacity: 0, x: '100%', skewX: 50 }}
            transition={{
              duration: 0.3
            }}
          >
            <span className="text-lg font-extrabold text-zve-corphish">{currentCase.title}</span>
            <Content className="content-5xl children-headings:mb-5">{currentCase.recap?.excerpt}</Content>
            <LosseLink to={currentCase.uri} className="btn btn--primary mt-7">
              <span>{fields.readmoretext}</span>
              <Arrow className="ml-3" />
            </LosseLink>
          </motion.div>
        </AnimatePresence>
      </div>
    </section>
  )
}
