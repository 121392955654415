export default function ArrowExternal({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" className={className}>
      <path
        fill="#F36420"
        d="M.293 14.293a1 1 0 101.414 1.414L.293 14.293zM16 1a1 1 0 00-1-1H6a1 1 0 000 2h8v8a1 1 0 102 0V1zM1.707 15.707l14-14L14.293.293l-14 14 1.414 1.414z"
      />
    </svg>
  )
}
