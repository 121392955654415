import { useLoaderData, useNavigate } from '@ubo/losse-sjedel'
import { Fragment } from 'react'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Post } from '~/graphql/types'
import useMediaQuery from '~/hooks/useMediaQuery'

interface Crumb {
  pathname: string
  label: string
}

function addBreadcrumbBasedOnPostType(postType: string): Crumb | null {
  if (postType === 'education') {
    return {
      pathname: '/opleidingen/',
      label: 'Opleidingen en cursussen'
    }
  }

  return null
}

function addBreadcrumbBasedOnParent(parentNode: Post) {
  if (!parentNode.uri) {
    return
  }
  if (!parentNode.title) {
    return
  }

  return {
    pathname: parentNode.uri,
    label: parentNode.title
  }
}

interface BreadcrumbsProps {
  title?: string
  inBanner?: boolean
}

export default function Breadcrumbs({ title, inBanner = false }: BreadcrumbsProps) {
  const data = useLoaderData<LoaderData>()
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const navigate = useNavigate()

  const additionalBreadcrumbs: Crumb[] = []

  if (data.page?.contentType?.node?.name) {
    const crumb = addBreadcrumbBasedOnPostType(data.page.contentType.node.name)
    if (crumb) additionalBreadcrumbs.push(crumb)
    const parentCrumb = data.page.parent ? addBreadcrumbBasedOnParent(data.page.parent.node as Post) : null
    if (parentCrumb) additionalBreadcrumbs.push(parentCrumb)
  }

  const items: Crumb[] = [
    { pathname: '/', label: 'Home' },
    ...additionalBreadcrumbs,
    { pathname: '', label: title || data.page.title || '' }
  ]

  return (
    <>
      {isMobile ? (
        <div
          onClick={() => {
            if (document.referrer === '') {
              navigate('/')
            } else {
              navigate(-1)
            }
          }}
          className={clsx(inBanner && 'text-iw-jasmine', !inBanner && 'text-iw-acai', 'flex items-center gap-3')}
        >
          <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.86875 8.69376C4.92733 8.63565 4.97382 8.56653 5.00555 8.49037C5.03728 8.41421 5.05362 8.33251 5.05362 8.25001C5.05362 8.1675 5.03728 8.08581 5.00555 8.00965C4.97382 7.93348 4.92733 7.86436 4.86875 7.80626L2.00625 4.94376C1.94767 4.88566 1.90117 4.81653 1.86944 4.74037C1.83771 4.66421 1.82138 4.58251 1.82138 4.50001C1.82138 4.4175 1.83771 4.33581 1.86944 4.25965C1.90117 4.18348 1.94767 4.11436 2.00625 4.05626L4.86875 1.19376C4.92733 1.13566 4.97382 1.06653 5.00555 0.990368C5.03728 0.914205 5.05362 0.832514 5.05362 0.750007C5.05362 0.6675 5.03728 0.585809 5.00555 0.509647C4.97382 0.433484 4.92733 0.364359 4.86875 0.306257C4.75164 0.18985 4.59324 0.124512 4.42812 0.124512C4.26301 0.124512 4.1046 0.18985 3.9875 0.306257L1.11875 3.17501C0.767627 3.52657 0.570404 4.00313 0.570404 4.50001C0.570404 4.99688 0.767627 5.47344 1.11875 5.82501L3.9875 8.69376C4.1046 8.81016 4.26301 8.8755 4.42812 8.8755C4.59324 8.8755 4.75164 8.81016 4.86875 8.69376V8.69376Z"
              fill="white"
            />
          </svg>
          Vorige
        </div>
      ) : (
        <div className="flex flex-wrap items-center text-lg">
          {items.map((crumb, index) => {
            const active = index === items.length - 1

            return (
              <Fragment key={crumb.pathname}>
                <LosseLink
                  to={crumb.pathname}
                  className={clsx(
                    active && inBanner && 'text-iw-jasmine font-bold',
                    !active && inBanner && 'hover:text-iw-jasmine font-light text-zve-corphish',
                    active && !inBanner && 'text-iw-acai',
                    !active && !inBanner && 'smooth text-zve-corphish underline hover:text-zve-delphox',
                    !!crumb.pathname && 'hover:underline focus:opacity-50',
                    'text-sm'
                  )}
                >
                  {crumb.label}
                </LosseLink>
                {items.length !== index + 1 && <Separator inBanner={inBanner} />}
              </Fragment>
            )
          })}
        </div>
      )}
    </>
  )
}

function Separator({ inBanner }: { inBanner: boolean }) {
  return (
    <div className={clsx('mx-3 h-2 w-2', !inBanner && 'text-ab-tidal/60', inBanner && 'text-iw-jasmine/60')}>
      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-full w-full">
        <path
          d="M6 5.5C6.00037 5.71535 5.95805 5.92865 5.87545 6.12764C5.79286 6.32664 5.67162 6.5074 5.51871 6.65953L1.16331 11L-5.06849e-08 9.84047L4.35458 5.5L-4.3014e-07 1.15954L1.16331 -5.08498e-08L5.51789 4.34047C5.67095 4.49254 5.79234 4.67327 5.87507 4.87226C5.95781 5.07126 6.00027 5.28459 6 5.5Z"
          fill="currentColor"
        />
      </svg>
    </div>
  )
}
