import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { FormDuufField, FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section data-component="FormContact" className="section bg-zve-mightyena">
      <div className="container">
        <div className="mx-auto max-w-screen-lg">
          <div className="flex flex-col overflow-hidden bg-white sm:flex-row sm:rounded-br-3xl lg:rounded-md lg:rounded-br-md">
            <div className="flex w-full flex-col px-6 pb-6 pt-10 sm:order-last sm:w-[60%] lg:px-10">
              <Content className="content-7xl content-border mb-10">{fields.title}</Content>
              <Form
                generate={false}
                data={fields.form}
                renderLoading={() => <Loading />}
                renderStatus={({ content }) => (
                  <Content>{content}</Content>
                )}
                className="form-contact h-full"
              >
                <div className="grid h-full grid-cols-2 gap-x-8">
                  <div className="col-span-2 sm:col-span-1">
                    <FormDuufGroup showIds={[4, 10]} />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <FormDuufGroup showIds={[9, 11]} />
                  </div>
                  <div className="col-span-2">
                    <FormDuufField id={2} />
                  </div>
                  <div className="col-span-2 mt-auto">
                    <FormDuufSubmit />
                  </div>
                </div>
              </Form>
            </div>
            <div className="relative w-full sm:order-first sm:w-[40%]">
              <div className="absolute bottom-0 left-0 h-32 w-full bg-gradient-to-t from-black/80 to-transparent" />
              <LossePlaatjie
                maxWidth={1000}
                src={fields.image}
                className="h-full max-h-[500px] w-full max-w-[unset] object-cover sm:max-h-[unset]"
              />
              <div className="absolute bottom-0 flex w-full justify-center px-4 py-12">
                <Content className="content-5xl text-white text-shadow">{fields.description}</Content>
              </div>
            </div>
          </div>
          <div className="mt-5 flex flex-col justify-end text-white sm:flex-row">
            {fields.links?.map((edge) => {
              return (
                <LosseLink
                  to={edge?.link?.url}
                  key={edge?.link?.title}
                  className="group mb-4 mr-3 inline-flex items-center sm:mr-9"
                  target={edge?.link?.target || '_self'}
                >
                  <LossePlaatjie maxWidth={500} src={edge?.icon} className="mr-2" />
                  <span className="group-hover:underline">{edge?.link?.title}</span>
                </LosseLink>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
