import { LosseBlogBink, useLosseBlogBink, useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import Pagination from '~/components/elements/Pagination'
import Preview, { type Edge } from '~/components/elements/posts/Preview'
import type { Education, Page_Flexcontent_Flex_Posts } from '~/graphql/types'

export default function PostsEducations({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section id="PostsEducations" className="section">
      <div className="container">
        <div className="mx-auto max-w-screen-xl">
          <Content className="children-h2:text-3xl lg:children-h2:text-5xl">{fields.description}</Content>
          <div>
            {/* @ts-ignore */}
            <LosseBlogBink pageInfo={fields.posts?.pageInfo}>
              <Overview fields={fields} />
            </LosseBlogBink>
          </div>
        </div>
      </div>
    </section>
  )
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const { hasNextPage, hasPrevPage } = useLosseBlogBink()
  const posts = fields.posts?.edges as Edge<Education>[]
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div className="flex flex-col gap-8 pt-8 xl:gap-16 xl:pt-16">
      <div className="flex flex-wrap justify-center gap-4 lg:gap-8">
        <div
          key="no-filter"
          onClick={() => {
            searchParams.delete('_f')
            setSearchParams(searchParams, {
              state: {
                scroll: false
              }
            })
          }}
          className={clsx(
            !searchParams.get('_f') ? `bg-zve-shiny-bayleef text-zve-delphox` : ``,
            'smooth relative cursor-pointer rounded-md px-4 py-2 font-semibold uppercase text-zve-corphish hover:bg-zve-furret xl:px-8 xl:py-3'
          )}
        >
          Alle opleidingen
        </div>

        {fields.filters?.map((filter, index) => {
          const activeFilters = searchParams.get('_f')?.split(',') || []
          const active = activeFilters.includes(filter?.databaseId.toString() || '-1')

          return (
            <div
              key={index}
              onClick={() => {
                searchParams.set('_f', filter?.databaseId.toString() || '')
                setSearchParams(searchParams, {
                  state: {
                    scroll: false
                  }
                })
              }}
              className={clsx(
                active ? `bg-zve-shiny-bayleef text-zve-delphox` : ``,
                'smooth relative cursor-pointer rounded-md px-4 py-2 font-semibold uppercase text-zve-corphish hover:bg-zve-furret xl:px-8 xl:py-3'
              )}
            >
              {filter?.name}
            </div>
          )
        })}
      </div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 lg:gap-[50px]">
        {posts.map((newsItem, index) => {
          return <Preview post={newsItem} key={index} className="col-span-1" />
        })}
      </div>
      {(hasNextPage || hasPrevPage) && (
        <div className="flex justify-center py-6 lg:py-10">
          <Pagination />
        </div>
      )}
    </div>
  )
}
