import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentTarget({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section id="ContentTarget" className="section">
      <div className="container">
        <div className="mx-auto flex max-w-screen-lg flex-col gap-12">
          <Content className="children-h2:text-5xl children-h2:leading-[1.2]">{fields.title}</Content>
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-4 lg:gap-10">
            <div className="flex flex-col items-center justify-center gap-4 rounded-md bg-zve-snorlax p-4 lg:gap-6 lg:p-10">
              <LossePlaatjie maxWidth={2000} src={fields.image} alt="target-icon" />
              <div>
                <Content className="text-[13px] children-strong:text-base children-strong:leading-[2]">{fields.target}</Content>
              </div>
            </div>
            <div className="col-span-1 lg:col-span-3">
              <Content className="children-h2:text-3xl children-h2:leading-[1.2] lg:children-h2:text-5xl">{fields.description}</Content>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
