import { type LoaderData } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import Logo from '~/components/elements/svg/Logo'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
  rest: any
}

export default function Header() {
  return (
    <nav className="absolute left-0 top-0 right-0 z-50 mx-auto bg-gradient-to-b from-zve-black via-zve-black/50 px-7 transition-all lg:px-14">
      <div className="mx-auto flex h-full max-w-screen-2xl items-center justify-between border-b border-white py-2 transition-all">
        <LosseLink aria-label="Home" className="lg:pl-5" to="/">
          <Logo className="h-14 w-auto sm:h-auto" />
        </LosseLink>

        <HeaderDesktop />
        <HeaderMobile />
      </div>
    </nav>
  )
}
