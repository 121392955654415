export default function LogoAlternate({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="158" height="148" fill="none" viewBox="0 0 158 148" className={className}>
      <path
        fill="#EA5B0C"
        d="M46.647 12.875s-24.746 16.63-9.28 37.895C52.834 72.035 69.44 90.393 69.44 90.393l.016 53.175S8.365 75.896 10.303 50.762c1.93-25.134 15.854-34.414 36.344-37.895v.008zM116.971 12.875s24.746 16.63 9.279 37.895c-15.466 21.265-32.072 39.623-32.072 39.623l-.015 53.175s61.09-67.664 59.159-92.79c-1.93-25.134-15.854-34.414-36.344-37.895l-.007-.008z"
      />
      <path
        fill="#fff"
        d="M157.703 39.241H0v18.63h157.703V39.24zM83.914 86.578l-.178 8.815s-.272.659-3.489 3.97c-5.179 5.318-3.837 8.651-3.837 8.651s.186 2.527 3.798 4.14c0-1.488-.279-4.264-.279-4.264s-.72-2.473 2.086-5.008 7.543-5.76 5.961-10.063c-1.054-2.86-3.132-5.45-4.062-6.248v.007zM84.022 113.712l.287 5.443s-2.116 3.628-3.675 4.744c-1.558 1.117-4.682 5.132.985 8.458.124-2.628.008-4.861.008-4.861s2.31-1.387 4.132-4.426c1.814-3.039 1.907-8.613-1.745-9.358h.008zM84.216 139.567s.303 1.946-.077 3.737c1.1-.217 3.24-2.535.077-3.737z"
      />
      <path
        fill="#fff"
        d="M82.347 147.165l1.846.015-.434-20.846-1.807 1.705.395 19.126zM80.448 106.154l.713 16.157 2.721-3.303-.357-16.598s-2.504 1.76-3.077 3.737v.007zM79.495 99.169l3.83-3.799.457-27.746s-.031-4.148-3.388-4.047c-3.357.1-2.768 4-2.768 4l1.869 31.592z"
      />
      <path
        fill="#fff"
        d="M74.068 70.749s2.225-1.582 3.744-1.388c1.52.194 4.59.744 4.419 2.465-.178 1.714-.357 2.187-2.512 1.737-2.155-.45-3.419-1.9-3.643.457-.225 2.357-.489 5.56 1.891 8.133l.419 5.706s-12.598-11.07-4.318-17.102v-.008z"
      />
      <path
        fill="#fff"
        d="M68.603 96.758c1.07-7.535-3.21-9.342-3.21-9.342s1.853 3.62-.317 9.117c-.776 1.97-1.125 3.985-1.047 5.869-.954-2.147-.768-3.543-.217-5.512 0 0-4.342 5.086-.962 11.202 3.38 6.125 1.032 9.924-2.263 4.481-.52-.852-.93-1.643-1.264-2.364.147-2.186-.07-4.497-.651-6.807-1.954-7.636.729-12.272.729-12.272s-3.877 1.388-4.83 8.047c-.737-1.806-1.783-2.938-1.783-2.938s1.395 5.194-1.093 8.776c-2.481 3.581-1.21-1.962-.714-3.404 0 0-4.706 4.047-2.674 11.242-2.008-1.28-4.21-5.427 2.163-14.296 7.714-10.737.209-21.77.209-21.77s1.496 10.242-3.442 16.707c-4.939 6.466-1.752-3.984-.636-6.675 0 0-7.179 5.66-6.97 17.149a7.188 7.188 0 00.101 1.892c.047.519.326 1.573.404 2.116 0 0 14.714 18.901 16.807 21.351 2.093 2.45 12.87 14.776 12.87 14.776l-.357-40.259c-1.031-.791-1.481-2.582-.845-7.078l-.008-.008zm-16.684 16.157c-.155-1.194.248-2.946 1.877-5.451a8.4 8.4 0 00.542-.953c-.232 4.024-1.271 5.721-2.426 6.404h.007zM73.51 77.594h.093l.04-.147-.125.147h-.008zM122.397 101.851v-.031c-1.109 5.807-4.481 6.272-3.047 1.628 4.528-13.504-3.45-21.14-3.45-21.14.752 2.441 2.69 11.814-1.085 5.767-3.776-6.055-1.659-14.854-1.659-14.854s-7.396 8.97-1.551 18.994c5.853 10.016 1.326 12.931-.302 12.931-1.628 0-4.605-.263-3.473-9.148 1.132-8.884-3.396-11.016-3.396-11.016s1.962 4.272-.341 10.753c-.822 2.325-1.186 4.698-1.109 6.923-1.007-2.535-.814-4.179-.232-6.505 0 0-4.59 6.001-1.016 13.218 3.574 7.218 1.093 11.707-2.395 5.288A31.699 31.699 0 0198 111.868c.155-2.574-.07-5.303-.69-8.024-2.063-9.009.775-14.474.775-14.474s-2.334.946-3.915 4.783l-.016 29.421v20.118l9.04-10.458 8-9.699c4.156-5.101 7.017-8.76 11.552-14.815 3.364-5.473 1.946-11.047 1.946-11.047-1.093.628-1.845 2.155-2.295 4.186v-.008zM66.719.781v4.504l-9.613 23.087h9.613v4.598H51.912v-4.505l9.613-23.087h-9.148V.781h14.35-.008zM81.945 27.039L85.806.78h4.644L85.48 32.97h-7.543L72.967.78h5.102l3.86 26.258h.016zM102.45 14.348h6.946v4.597h-6.946v9.427h8.737v4.598H97.396V.78h13.791v4.597h-8.737v8.97z"
      />
      <path
        fill="#1D1D1B"
        d="M39.298 53.67L35.174 43.5h1.76l1.969 5.008c.543 1.372 1.015 2.613 1.349 3.806h.038c.357-1.178.884-2.457 1.443-3.79l2.14-5.024h1.744l-4.52 10.171h-1.799zM54.477 48.895h-4.915v3.666h5.481v1.101h-7.109V43.491h6.83v1.1H49.57v3.218h4.915v1.086h-.008zM59.733 43.491v10.171h-1.635V43.491h1.635zM63.524 43.491h1.636v9.07h5.404v1.101h-7.04V43.491zM74.952 43.491v10.171h-1.636V43.491h1.636zM88.364 53.213a14.42 14.42 0 01-3.884.558c-1.915 0-3.489-.396-4.73-1.341-1.085-.846-1.76-2.202-1.76-3.791.016-3.032 2.606-5.257 6.846-5.257 1.466 0 2.605.256 3.156.465l-.396 1.07c-.674-.24-1.52-.434-2.799-.434-3.077 0-5.085 1.543-5.085 4.094 0 2.55 1.93 4.108 4.876 4.108 1.07 0 1.799-.124 2.179-.27v-3.032h-2.574v-1.055h4.163v4.892l.008-.007zM93.435 43.491v4.256h6.116v-4.256h1.652v10.171h-1.652v-4.767h-6.116v4.767h-1.636V43.491h1.636zM111.521 48.895h-4.915v3.666h5.481v1.101h-7.109V43.491h6.83v1.1h-5.195v3.218h4.915v1.086h-.007zM116.769 43.491v10.171h-1.636V43.491h1.636zM120.56 43.631a28.34 28.34 0 013.473-.209c2.341 0 4.016.434 5.125 1.272 1.124.83 1.783 2.008 1.783 3.651 0 1.644-.636 3.016-1.822 3.954-1.178.953-3.132 1.465-5.59 1.465a31.71 31.71 0 01-2.961-.124V43.631h-.008zm1.636 8.993c.411.062 1.015.078 1.651.078 3.489 0 5.388-1.566 5.388-4.318.016-2.404-1.667-3.923-5.124-3.923-.845 0-1.481.062-1.915.14v8.031-.008z"
      />
    </svg>
  )
}
