import { LossePlaatjie } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import Arrow from '~/components/elements/svg/Arrow'
import type { Page_Flexcontent_Flex_Klantcases } from '~/graphql/types'

export default function KlantcasesPreview({ fields }: { fields: Page_Flexcontent_Flex_Klantcases }) {
  return (
    <section id="KlantcasesPreview" className="section">
      <div className="container">
        <div className="relative mx-auto grid max-w-[1280px] grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col items-center justify-center gap-5 bg-zve-furret p-4 sm:py-6 sm:pl-6 sm:pr-6 lg:rounded-l-[4px] lg:pr-20 lg:pl-10">
            <div className="flex gap-3">
              <Content className="children-h2:text-3xl lg:children-h2:text-5xl">{fields.case?.recap?.description}</Content>

              <div className="top-6 right-6 flex aspect-square h-20 w-20 items-center justify-center bg-white p-5 sm:h-28 sm:w-28 lg:absolute lg:top-8 lg:right-0 lg:left-0 lg:mx-auto lg:rounded-[4px]">
                <LossePlaatjie maxWidth={500} className="object-contain" src={fields.case?.recap?.logo} alt="case-logo" />
              </div>
            </div>
            <div className="flex w-full flex-col justify-start gap-3 sm:flex-row">
              <Button to="#inschrijven" className="btn btn--corphish">
                Inschrijven
                <Arrow />
              </Button>
              <Button to={fields.case?.uri || '/'} className="btn btn--primary">
                Lees hele case
                <Arrow />
              </Button>
            </div>
          </div>
          <div className="bg-zve-black">
            <LossePlaatjie maxWidth={1000} src={fields.case?.recap?.image} alt="preview-image" />
          </div>
        </div>
      </div>
    </section>
  )
}
