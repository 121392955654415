import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { ContentNode, Education, Education_Recap } from '~/graphql/types'
import Arrow from '../svg/Arrow'

export type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
    title?: string
    recap?: Education_Recap
  }
}

export default function Preview({ post, className }: { post: Edge<Education>; className?: string }) {
  return (
    <LosseLink
      to={post.node.uri}
      key={post.node.title}
      className="smooth group relative flex !h-auto flex-col overflow-hidden rounded-lg bg-zve-furret"
    >
      <div className="relative h-32 min-h-[128px] overflow-hidden lg:h-48 lg:min-h-[192px]">
        <LossePlaatjie
          maxWidth={1000}
          src={post.node.recap?.thumbnail}
          className="smooth h-32 object-cover group-hover:scale-105 lg:h-48"
        />
        <div className="absolute bottom-0 top-0 h-full w-full bg-gradient-to-t from-black via-transparent to-transparent" />
        {/* <div className="absolute bottom-0 left-0 w-full px-4 py-2 xl:px-8 xl:py-4">
          <div className="font-semibold text-white">{post.node.recap?.identification}</div>
        </div> */}
      </div>
      <div className="flex h-full flex-col p-4 font-bold xl:p-4">
        <span>{post.node.categories?.edges[0].node.name}</span>
        <h2 className="mb-4 mt-4 text-2xl lg:mb-16">{`${post.node.title}`}</h2>
        <LosseLink to={post.node.uri} className="group mt-auto inline-flex items-center text-lg text-zve-delphox">
          <span>MEER INFO</span>
          <Arrow className="translate-x-3 transition-transform group-hover:translate-x-5" />
        </LosseLink>
      </div>
    </LosseLink>
  )
}
