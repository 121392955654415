export default function Logo({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="82" fill="none" viewBox="0 0 88 82" className={className}>
      <path
        fill="#EA5B0C"
        d="M25.876 6.709s-13.728 9.224-5.148 21.02c8.58 11.797 17.79 21.98 17.79 21.98l.01 29.497S4.64 41.667 5.715 27.725c1.07-13.942 8.794-19.09 20.16-21.02v.004zM64.885 6.709s13.727 9.224 5.148 21.02c-8.58 11.797-17.791 21.98-17.791 21.98l-.009 29.497S86.121 41.67 85.05 27.734c-1.07-13.942-8.794-19.09-20.16-21.021l-.005-.004z"
      />
      <path
        fill="#1D1D1B"
        d="M87.48 21.335H0v10.334h87.48V21.335zM46.548 47.593l-.099 4.89s-.15.366-1.935 2.202c-2.873 2.95-2.129 4.8-2.129 4.8s.104 1.401 2.108 2.296c0-.826-.155-2.366-.155-2.366s-.4-1.371 1.157-2.778c1.556-1.406 4.184-3.195 3.307-5.582-.585-1.587-1.738-3.023-2.254-3.466v.004zM46.608 62.645l.16 3.019s-1.175 2.013-2.039 2.632c-.864.62-2.597 2.847.546 4.692.07-1.458.005-2.697.005-2.697s1.281-.77 2.292-2.455c1.006-1.686 1.058-4.778-.968-5.191h.004zM46.716 76.987s.168 1.08-.043 2.073c.61-.12 1.797-1.407.043-2.073z"
      />
      <path
        fill="#1D1D1B"
        d="M45.68 81.201l1.023.009-.241-11.564-1.002.946.22 10.61zM44.626 58.452l.395 8.962 1.51-1.832-.198-9.207s-1.39.976-1.707 2.073v.004zM44.097 54.577l2.124-2.107.254-15.391s-.017-2.301-1.88-2.245c-1.861.056-1.534 2.219-1.534 2.219l1.036 17.524z"
      />
      <path
        fill="#1D1D1B"
        d="M41.086 38.812s1.235-.878 2.078-.77c.843.107 2.545.413 2.45 1.367-.098.95-.197 1.213-1.392.964-1.196-.25-1.897-1.054-2.022.253-.124 1.308-.27 3.084 1.05 4.512l.232 3.165s-6.988-6.141-2.396-9.487v-.004z"
      />
      <path
        fill="#1D1D1B"
        d="M38.055 53.24c.593-4.18-1.78-5.182-1.78-5.182s1.027 2.008-.177 5.057c-.43 1.093-.623 2.21-.58 3.256-.53-1.192-.426-1.966-.12-3.058 0 0-2.41 2.821-.534 6.214 1.875 3.398.572 5.505-1.256 2.486a12.993 12.993 0 01-.7-1.312 12.183 12.183 0 00-.362-3.776c-1.084-4.236.404-6.807.404-6.807s-2.15.77-2.679 4.464a5.893 5.893 0 00-.99-1.63s.775 2.881-.605 4.868c-1.377 1.987-.671-1.088-.396-1.888 0 0-2.61 2.245-1.484 6.236-1.114-.71-2.335-3.01 1.2-7.93 4.279-5.957.116-12.076.116-12.076s.83 5.68-1.91 9.267c-2.739 3.587-.971-2.21-.352-3.702 0 0-3.982 3.139-3.866 9.512-.026.331-.013.684.056 1.05.026.288.18.873.224 1.174 0 0 8.162 10.484 9.323 11.843 1.161 1.359 7.139 8.197 7.139 8.197l-.198-22.333c-.572-.438-.822-1.432-.469-3.926l-.004-.004zM28.8 62.202c-.086-.662.138-1.634 1.041-3.023.116-.177.215-.353.301-.53-.129 2.233-.705 3.175-1.346 3.553h.004zm11.977-19.593h.052l.021-.082-.069.082h-.004zM67.896 56.065v-.017c-.615 3.221-2.486 3.48-1.69.903 2.511-7.491-1.914-11.727-1.914-11.727.417 1.354 1.492 6.554-.602 3.2-2.095-3.36-.92-8.24-.92-8.24s-4.103 4.975-.86 10.536c3.246 5.556.735 7.173-.168 7.173s-2.555-.146-1.927-5.075c.628-4.928-1.884-6.11-1.884-6.11s1.088 2.369-.189 5.964a10.508 10.508 0 00-.615 3.84c-.559-1.406-.451-2.318-.129-3.608 0 0-2.546 3.329-.563 7.333 1.982 4.003.606 6.493-1.329 2.933a17.638 17.638 0 01-.744-1.549 15.859 15.859 0 00-.383-4.45c-1.144-4.998.43-8.03.43-8.03s-1.294.525-2.171 2.654l-.009 16.32v11.16l5.014-5.802 4.438-5.38c2.306-2.83 3.892-4.859 6.408-8.218 1.867-3.036 1.08-6.128 1.08-6.128-.607.349-1.024 1.196-1.273 2.322v-.004z"
      />
      <path
        fill="#fff"
        d="M37.01 0v2.499l-5.333 12.806h5.333v2.55h-8.214v-2.498L34.129 2.55h-5.075V0h7.96-.004zM45.456 14.566L47.598 0h2.576l-2.757 17.855h-4.184L40.476 0h2.83l2.141 14.566h.009zM56.83 7.526h3.854v2.55H56.83v5.23h4.847v2.55h-7.65V0h7.65v2.55H56.83v4.976zM21.8 29.338l-2.289-5.642h.976l1.093 2.778c.3.76.563 1.449.748 2.111h.021c.198-.654.49-1.363.8-2.103l1.187-2.786h.968l-2.507 5.642h-.998zM30.22 26.689h-2.727v2.034h3.04v.61H26.59v-5.642h3.789v.61h-2.882v1.786h2.727v.602h-.005zM33.135 23.691v5.642h-.907v-5.642h.907zM35.238 23.691h.907v5.032h2.998v.61h-3.905v-5.642zM41.577 23.691v5.642h-.908v-5.642h.908zM49.017 29.084a8 8 0 01-2.155.31c-1.062 0-1.935-.22-2.623-.744-.602-.469-.976-1.222-.976-2.103.008-1.682 1.445-2.916 3.797-2.916.813 0 1.445.142 1.75.258l-.219.594c-.374-.134-.843-.241-1.553-.241-1.707 0-2.82.856-2.82 2.27 0 1.415 1.07 2.28 2.704 2.28.594 0 .998-.07 1.209-.15V26.96h-1.428v-.585h2.31v2.713l.004-.004zM51.83 23.691v2.361h3.392v-2.36h.916v5.641h-.916V26.69H51.83v2.644h-.907v-5.642h.907zM61.862 26.689h-2.727v2.034h3.04v.61h-3.943v-5.642h3.79v.61H59.14v1.786h2.726v.602h-.004zM64.774 23.691v5.642h-.908v-5.642h.908zM66.876 23.769c.55-.07 1.209-.116 1.927-.116 1.299 0 2.228.24 2.843.705.623.46.989 1.114.989 2.025 0 .912-.353 1.673-1.01 2.194-.654.529-1.738.812-3.102.812a17.59 17.59 0 01-1.642-.068v-5.552h-.005zm.908 4.988c.228.035.563.043.916.043 1.935 0 2.989-.869 2.989-2.395.008-1.333-.925-2.176-2.843-2.176-.469 0-.821.034-1.062.077v4.456-.005z"
      />
    </svg>
  )
}
