import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import Arrow from '~/components/elements/svg/Arrow'
import type { Case, Page_Flexcontent_Flex_Klantcases } from '~/graphql/types'

export default function OtherCases({ fields }: { fields: Page_Flexcontent_Flex_Klantcases }) {
  return (
    <section id="OtherCases" className="section">
      <div className="container">
        <div className="col-span-6 grid gap-5 lg:grid-cols-10">
          {fields.othercases![0] && <CaseBlock position="left" className="bg-zve-furret" fields={fields.othercases![0]} />}
          {fields.othercases![1] && <CaseBlock position="right" className="bg-zve-cutiefly" fields={fields.othercases![1]} />}
        </div>
      </div>
    </section>
  )
}

function CaseBlock({ position, fields, className }: { position: 'left' | 'right'; fields: Case; className: string }) {
  return (
    <>
      {position === 'right' && <div className="col-span-4 hidden lg:block" />}
      <div className="col-span-6">
        <div className={clsx(className && className, 'flex flex-col items-center gap-4 rounded-[6px] p-6 lg:flex-row lg:gap-16 lg:p-11')}>
          <div className="flex h-[68px] w-[68px] items-center justify-center rounded-[6px] bg-white p-3 lg:min-h-[100px] lg:min-w-[100px] lg:p-5">
            <LossePlaatjie maxWidth={400} src={fields.recap?.logo} alt="logo" />
          </div>
          <Content className="children-h2:text-2xl lg:children-h2:text-4xl">{fields.recap?.description}</Content>
          <div className="flex items-center">
            <Button to={fields.uri || '/'} className="btn btn--primary-bare">
              <Arrow />
            </Button>
          </div>
        </div>
      </div>
      {position === 'left' && <div className="col-span-4 hidden lg:block" />}
    </>
  )
}
